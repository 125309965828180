.imgLogoIni{
    height: 105%;
    float: left; 
}
.imgicon{
    height:30px;
    width: 40px;
    margin-top: 15px;
}
.header2{
    background-color: #095394;
    width: 100%;
    height: 85px;
    margin: auto;
    display: block;
    border-bottom:2px solid rgb(211, 211, 216);
}
.header_top{
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
}
.teste{
    float: right;
    margin-right: 15px;
}
.header_top_text{
    color:white;
    font-size: 20px;
    text-align: center;
}

