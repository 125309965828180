
.footer2{
    max-width: 1200px;
    margin: auto;
}
.textt{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.footer2_margim{
    float: left;
}
.footer2_margim2{
    float: right;
    
}
.footer_color{
    background-color: rgb(61, 61, 105);
}

@media(max-width:800px){
    .footer2_margim{
        float: none;
        margin-left: 0;
        margin-top: 5px;
        align-items: center;
        justify-content: center;
    }
    .footer2_margim2{
        float: none;
        margin-left: 0;
        margin-top: 15px;
        align-items: center;
        justify-content: center; 
    }
}
.footer_text2{
    float: right;
    font-family: 'Arial';
    margin-top: -20px;
    margin-right: 15px;
}
.footer_h1{
    margin-top: 15px;
    margin-right: 15px;
    font-size: 15px;
    float: right;
    color: white;
}
.footer_h{
    margin-top: 15px;
    margin-left: 15px;
    font-size: 15px;
    color: white;
}
.imgLogoFooter{
    margin-top: -30px;
    padding: 0;
    height: 90px;
    float: left;
    margin-left: 0px; 
}
@media(max-width:800px){
    .imgLogoFooter{
        float: none;
        margin-left: 0;
        align-items: center;
        justify-content: center;
    }
}


