@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
.aling{
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right bottom, #2512f4, #201bd3, #201fb3, #211f93, #211e74, #24216d, #272467, #292660, #302e70, #383780, #3f4090, #4649a1);
  padding-top: 100px;
  padding-bottom: 50px;
  border-radius: 27px;
  box-shadow: 0 10px 30px 0 rgba(178, 205, 218, 0.692);
}
.imgLogo3{
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-left: 10px;
    -o-object-fit: cover;
    object-fit: cover; 
}
.login_Usuario2{
  font-family: "Karla", sans-serif;
  font-size: 20px;
  color: white;
}
.login_Usuario3{
  font-family: "Karla", sans-serif;
  font-size: 30px;
  color: white;
}
@media(max-width:900px){
  .login_Usuario3 {
    margin-left: 10px;
  }
}
@media(max-width:900px){
  .login_Usuario2 {
    margin-left: 10px;
  }
}
