@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
.main_Titulo{
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
    max-width: 1200px;
    font-style: italic;
    padding: 20px;
    border:1px solid rgb(214, 205, 205)
}


.aling{
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right bottom, #2512f4, #201bd3, #201fb3, #211f93, #211e74, #24216d, #272467, #292660, #302e70, #383780, #3f4090, #4649a1);
  padding-top: 100px;
  padding-bottom: 50px;
  border-radius: 27px;
  box-shadow: 0 10px 30px 0 rgba(178, 205, 218, 0.692);
}
.imgLogo3{
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-left: 10px;
    object-fit: cover; 
}
.login_Usuario2{
  font-family: "Karla", sans-serif;
  font-size: 20px;
  color: white;
}
.login_Usuario3{
  font-family: "Karla", sans-serif;
  font-size: 30px;
  color: white;
}
@media(max-width:900px){
  .login_Usuario3 {
    margin-left: 10px;
  }
}
@media(max-width:900px){
  .login_Usuario2 {
    margin-left: 10px;
  }
}

.login-btn{
    padding: 13px 20px 12px;
      background-color: #000;
      border-radius: 4px;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      margin-bottom: 24px;
}
.login-btn:hover{
    color: black;
    background-color: white;
}
.container2usuario{
    width: 50px;
}
.aling{
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(237, 237, 241, 0.966); */
  background-image: linear-gradient(to right bottom, #2512f4, #201bd3, #201fb3, #211f93, #211e74, #24216d, #272467, #292660, #302e70, #383780, #3f4090, #4649a1);
  padding-top: 100px;
  padding-bottom: 50px;
  border-radius: 27px;
  box-shadow: 0 10px 30px 0 rgba(178, 205, 218, 0.692);
}
.login_titulo{
  font-family: "Karla", sans-serif;
  /* margin-top: -140px; */
  font-size: 30px;
}
.login_registro{
  font-family: "Karla", sans-serif;
  /* margin-top: -140px; */
  font-size: 20px;
  color: white;
}
.login_fundo{
  background-image: linear-gradient(to right bottom, #2512f4, #201bd3, #201fb3, #211f93, #211e74, #24216d, #272467, #292660, #302e70, #383780, #3f4090, #4649a1);
  /* background-color: aquamarine; */
}
.imgLogo{
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-left: 10px;
  /* padding-right: 1px; */
    object-fit: cover; 
}
@media(max-width:900px){
  .login_registro{
    margin-left: 10px;
  }
}




.login-btn{
    padding: 13px 20px 12px;
      background-color: #000;
      border-radius: 4px;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      margin-bottom: 18px;
}
.login-btn:hover{
    color: black;
    background-color: white;
}

@media(max-width:800px){
    .teste{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.imgLogoIni{
    height: 105%;
    float: left; 
}
.imgicon{
    height:30px;
    width: 40px;
    margin-top: 15px;
}
.header2{
    background-color: #095394;
    width: 100%;
    height: 85px;
    margin: auto;
    display: block;
    border-bottom:2px solid rgb(211, 211, 216);
}
.header_top{
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
}
.teste{
    float: right;
    margin-right: 15px;
}
.header_top_text{
    color:white;
    font-size: 20px;
    text-align: center;
}


*{
    margin: 0;
    padding: 0; 
}
body{
    background-color: #f7f7f7;
}
.main_evento{
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    padding-bottom: 200px;
}
@media(max-width:1150px){
    .main_evento{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.main_91_titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_91_titulo{
        font-size: 30px;
        text-align: center;
    }
}
.main_h2_titulo{
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 90px;
    background-color: #023859;
    background-image: linear-gradient(#023859,#02456c);
    padding: 20px;
    color: white;
    font-size: 40px;
}
@media(max-width:800px){
    .main_h2_titulo{
        margin-top: 200px;
        font-size: 30px;
        text-align: center;
    }
}
.main_textarea{
    border-radius: 5px;
    margin-left: 90px;
    width: 700px;
    height: 5rem;
    min-height: 5rem;
    margin-top: 15px; 
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
    resize: vertical;
    justify-content: justify;
}
.main_select{
    border-radius: 5px;
    width: 200px;
    display: row;
    padding: 10px;
    border: 1px solid rgb(212, 217, 221);
    background-color: white;
}
.main_img{
    width: 30px;
}
.main_file{
    display: none;
}
.main_file_estilizado{
    background-color: #0b74bb;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100px;
    cursor: pointer;
    margin-top: 20px;
    padding: 40px 20px;
    margin-left: 25px;
    font-family: 'Courier New', Courier, monospace;
}
.main_file_estilizado2{
    cursor: pointer;
    margin-left: 25px;
    width: 30vw;
    font-family: 'Courier New', Courier, monospace;
}
@media(max-width:800px){
    .main_file_estilizado2{
        width: 80vw;
    }
}
.btn_even2{
    padding: 10px 25px 10px 25px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    float: right;
    margin: 10px 15px; 
}
@media(max-width:800px){
.btn_even2{
    width: 92%;
    margin-right: 18px; 
    }   
}
.btn_even2:hover{
    background-color: white;
    color:black;
}
.form_input2{
    width: 90%;
    margin-left: 25px;
}
.form_input3{
    width: 90%;
    margin-left: 50px;
    margin-top: 30px;
    margin-bottom: 15px;
}
@media(max-width:900px){
    .form_input3{
        width: 50%;
        margin-left: 30px; 
        margin-right: -11px;
        margin-bottom: 10px;
        } 
    .btn_evento3{
        margin-right: 0px;  
        margin-left: 0;
        margin-top: 30px;
        border-radius: 4px;
        font-size: 12px;
        height: 35px;
        }
    .btn_evento4{
        margin-left: 1px;
        margin-top: 30px;
        border-radius: 4px;
        font-size: 12px;
        height: 35px;
        }    
}
.form_label_teste{
    margin-left: 25px;
    margin-bottom: 1px;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    margin-top: 10px;
    color:#0b74bb;
}
.form_label2{
    margin-left: 0px;
}
.form_label_sec{
    width: 68%;
    margin-right: auto;
}
@media(max-width:800px){
    .form_label_sec{
        width: 90%;
        margin-left: 25px;
    }
    .form_label2{
        margin-left: 25px;
    }
}
.btn_evento3{
    margin-left: 15px;  
    margin-top: 30px;
    border-radius: 4px;
    font-size: 12px;
    height: 35px;
}
.btn_evento3:hover{
    background-color: white;
    color: black;
    font-style: oblique;
}
.btn_evento4{
    margin-left: 5px;
    margin-top: 30px;
    border-radius: 4px;
    font-size: 12px;
    height: 35px;
}
.btn_evento4:hover{
    background-color: white;
    color: black;
    font-style: oblique;
}






.menu_l{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    /* height: 40px; */
    line-height: 3rem;
    /* background-color: #f3f2f0f5; */
    border-bottom: 1px solid orangered;
}
#menu ul {
    padding:0px;
    margin:0px;
    background-color: rgba(196, 17, 17, 0.192);
    list-style:none;
}
#menu  li { 
    display: inline-block; 
    font-size: 19px;
    position: relative;
    padding: 2px 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-decoration: none;
}
#menu li a {
    
    display: inline-block;
  
    /* border-bottom:3px solid #EDEDED; */
    
}
/*Botão sair*/
.header_top_button{
    background-color: #f3f2f0f5;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.header_top_button:hover{
    background-color: orangered;
    color: white;
    font-family: italic;
}

@media(max-width:800px){
    .nav-item{
        width: 100%;
    }
    .Navbar{
        background-color: #f3f2f0f5;
    }
    .menu_l{
        height: 60px;
        line-height: 2rem; 
    }
    .header_top_button{
        width: 100%;
        text-align: left;
    }
}
.menu_img{
    width: 19px;
    margin-top: -3px; 
    margin-right: 4px;
}
.menu_img_home{
    margin-top: -3px;
}
.menu_exit{
    text-decoration: none;
    background-color: none;
}
.menu_color1{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color2{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color3{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color4{
    color: black;
    border-radius: 1px;
    padding: 0;
    /* box-shadow: 1px 1px 1px 1px rgb(125, 131, 141); */
}
.menu_color1:hover{
    color: #d8521d;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color2:hover{
    color: #ff0000;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color3:hover{
    color: #548b54;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color4:hover{
    color: #b21cf7d7;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
    /* background-color: aliceblue; */
}




.footer2{
    max-width: 1200px;
    margin: auto;
}
.textt{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.footer2_margim{
    float: left;
}
.footer2_margim2{
    float: right;
    
}
.footer_color{
    background-color: rgb(61, 61, 105);
}

@media(max-width:800px){
    .footer2_margim{
        float: none;
        margin-left: 0;
        margin-top: 5px;
        align-items: center;
        justify-content: center;
    }
    .footer2_margim2{
        float: none;
        margin-left: 0;
        margin-top: 15px;
        align-items: center;
        justify-content: center; 
    }
}
.footer_text2{
    float: right;
    font-family: 'Arial';
    margin-top: -20px;
    margin-right: 15px;
}
.footer_h1{
    margin-top: 15px;
    margin-right: 15px;
    font-size: 15px;
    float: right;
    color: white;
}
.footer_h{
    margin-top: 15px;
    margin-left: 15px;
    font-size: 15px;
    color: white;
}
.imgLogoFooter{
    margin-top: -30px;
    padding: 0;
    height: 90px;
    float: left;
    margin-left: 0px; 
}
@media(max-width:800px){
    .imgLogoFooter{
        float: none;
        margin-left: 0;
        align-items: center;
        justify-content: center;
    }
}



.main_Titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
form{
    margin: 0;
    padding: 0;
}
*{
    margin: 0;
    padding: 0; 
}
body{
    background-color: #f7f7f7;
}
.main_evento{
    max-width: 1200px;
    margin: 0 auto;
    background-color: white(255, 255, 255, 0.986);
    padding-bottom: 100px;
    box-shadow: 0px 5px 19px 1px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_evento{
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media(max-width:800px){
    .main_evento{
        padding-bottom: 200px;
    }
}
.main_b1_titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_h1_titulo{
        font-size: 30px;
        text-align: center;
    }
}
.main_textarea{
    border-radius: 5px;
    margin-left: 90px;
    width: 700px;
    height: 5rem;
    min-height: 5rem;
    margin-top: 15px; 
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
    resize: vertical;
    justify-content: justify;
}
.main_select{
    border-radius: 5px;
    width: 200px;
    display: row;
    padding: 10px;
    border: 1px solid rgb(212, 217, 221);
    background-color: white;
}
.main_img{
    width: 30px;
}
.main_file{
    display: none;
}
.main_file_estilizado2{
    cursor: pointer;
    margin-left: 25px;
    width: 18vw;
    font-family: 'Courier New', Courier, monospace;
}
@media(max-width:800px){
    .main_file_estilizado2{
        width: 80vw;
    }
}
.btn_event{
    padding: 10px 25px 10px 25px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    float: right;
    margin: 10px 15px; 
    margin-bottom: 90px;
}
@media(max-width:800px){
    .btn_event{
        width: 95%;
        margin-left: 15px; 
        } 
}
.btn_event:hover{
    background-color: white;
    color:black;
}
.form_input{
    width: 90%;
    margin-left: 25px;  
}
.form_input:hover{
    border-color: #0b25bb;
    box-shadow: none;
}
.form-control:focus {
    border-color: #0004ff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 47, 255, 0.6);
  }
.top{
    margin-top: 10px;
}
.form_label{
    margin-left: 25px;
    margin-bottom: 1px;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    margin-top: 10px;
    color: #0b74bb;
}
.form_label2{
    margin-left: 0px;
    margin-left: 25px;
    margin-bottom: 1px;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    color: #0b74bb;
}

@media(max-width:800px){
    .form_label_sec{
        width: 90%;
        margin-left: 25px;
    }
    .form_label2{
        margin-left: 25px;
    }
}






*{
    margin: 0;
    padding: 0; 
}
body{
    background-color: rgba(255, 255, 255, 0.986);
}
.main_evento{
    max-width: 1200px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.986);
}
@media(max-width:1250px){
    .main_evento{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.main_31_titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_h1_titulo{
        font-size: 30px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.main_textarea{
    border-radius: 5px;
    margin-left: 90px;
    width: 700px;
    height: 5rem;
    min-height: 5rem;
    margin-top: 15px; 
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
    resize: vertical;
    justify-content: justify;
}
.main_select{
    border-radius: 5px;
    width: 200px;
    display: row;
    padding: 10px;
    border: 1px solid rgb(212, 217, 221);
    background-color: white;
}
.main_img{
    width: 30px;
}
.main_file{
    display: none;
}
.main_file_estilizado{
    background-color: #0b74bb;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    padding: 40px 20px;
    margin-left: 25px;
}
.btn_event{
    padding: 10px 25px 10px 25px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    float: right;
    margin: 10px 15px; 
}
@media(max-width:800px){
    .btn_event{
        width: 92%;
        margin-left: 18px; 
        } 
}
.btn_event:hover{
    background-color: white;
    color:black;
}
.form_input{
    width: 90%;
    margin-left: 25px;
}
.form_label{
    margin-left: 25px;
}
.form_label2{
    margin-left: 0px;
}
.form_label_sec{
    width: 68%;
    margin-right: auto;
}
@media(max-width:800px){
    .form_label_sec{
        width: 90%;
        margin-left: 25px;
    }
    .form_label2{
        margin-left: 25px;
    }
}


body{
    background-color:#ffffff;
}
.page_main_agenda{
    max-width: 1200px;   
    margin: 0 auto;
    box-shadow: 0px 5px 19px 1px rgb(206, 199, 199);
}
@media(max-width:1250px){
    .page_main_agenda{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.card_pad{
    display: flex; 
    max-width: 1200px;
    background-color: white;
}
.card_m{
    margin-right:30px;
    margin-left: 30px;
    box-shadow: 1px 1px 15px 1px rgb(182, 192, 192);
}
.card_m:hover{
    box-shadow: 1px;
}
.card_border{
    margin-top: 20px;
    margin-bottom: 20px;
}
.hh{
    max-width: 1200px;  
    margin: 0 auto;
}
.main_z1_titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
.main_h1_Sub{
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: orangered;
    font-size: 30px;
    margin: 0 auto;
    max-width: 1200px;
}
@media(max-width:800px){
    .main_h1_titulo{
        font-size: 30px;
        text-align: center;
    }
}
.card_border{
    border-radius: 19px;
}
.card_border_img{
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
}

/* .page_main_even{
    max-width: 1200px;  
    display: flex;
    flex-direction: row;
    margin-top: 11px;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 243, 243, 0.075);
    margin: 0 auto;
}
#page-evento .main{
    width: 100%;
    max-width: 1200px;
}
#page-evento input{
    border-radius: 5px;
    margin-left: 5px;
    width: 700px;
    padding: 10px;
    margin-top: 15px; 
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
}
#page-evento select{
    border-radius: 5px;
    margin-left: 5px;
    width: 700px;
    display: table-cell;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
}
#page-evento p{
    display: table-row;
}
#page-evento label{
    display: table-cell;
}
#page-evento input{
    display: table-cell;
}
#page-evento input:hover{
    box-shadow: 0.1px 0.1px 0.1px 0.1px;
    border-color: rgba(96, 139, 231, 0.52);
    outline-color: rgb(51, 179, 230);
}
fieldset{
    border: 0; 
}
legend{
    font: 700 3.4rem Archivo;
    color: rgb(50, 52, 63);
    text-align: center;
    padding-bottom: 25px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgb(208, 214, 214);
    font-family: Arial, Helvetica, sans-serif;
}
.btn_evento{
    align-items: center;
    justify-content: center;
    padding: 10px 25px 10px 25px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 24px;
}
@media(max-width:800px){
    .btn_evento{
        width: 92%;
        margin-right: 18px; 
        }   
    }
.btn_evento:first-of-type{
    margin-left: 600px;
}
.hh{
    max-width: 1200px;  
    margin: 0 auto;
} */



