.main_Titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}