@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
form{
    margin: 0;
    padding: 0;
}
*{
    margin: 0;
    padding: 0; 
}
body{
    background-color: #f7f7f7;
}
.main_evento{
    max-width: 1200px;
    margin: 0 auto;
    background-color: white(255, 255, 255, 0.986);
    padding-bottom: 100px;
    box-shadow: 0px 5px 19px 1px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_evento{
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media(max-width:800px){
    .main_evento{
        padding-bottom: 200px;
    }
}
.main_b1_titulo{
    max-width: 1200px;
    margin: 0 auto 30px;
    font-family: "Karla", sans-serif;
    font-style: italic;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
    color: rgb(50, 143, 248);
    font-size: 40px;
    border:1px solid rgb(238, 232, 232);
    box-shadow: 1px 1px 2px 2px rgb(206, 199, 199);
}
@media(max-width:800px){
    .main_h1_titulo{
        font-size: 30px;
        text-align: center;
    }
}
.main_textarea{
    border-radius: 5px;
    margin-left: 90px;
    width: 700px;
    height: 5rem;
    min-height: 5rem;
    margin-top: 15px; 
    border: 1px solid rgb(212, 217, 221);
    background-color: rgb(244, 245, 246);
    resize: vertical;
    justify-content: justify;
}
.main_select{
    border-radius: 5px;
    width: 200px;
    display: row;
    padding: 10px;
    border: 1px solid rgb(212, 217, 221);
    background-color: white;
}
.main_img{
    width: 30px;
}
.main_file{
    display: none;
}
.main_file_estilizado2{
    cursor: pointer;
    margin-left: 25px;
    width: 18vw;
    font-family: 'Courier New', Courier, monospace;
}
@media(max-width:800px){
    .main_file_estilizado2{
        width: 80vw;
    }
}
.btn_event{
    padding: 10px 25px 10px 25px;
    background-color: #000;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    float: right;
    margin: 10px 15px; 
    margin-bottom: 90px;
}
@media(max-width:800px){
    .btn_event{
        width: 95%;
        margin-left: 15px; 
        } 
}
.btn_event:hover{
    background-color: white;
    color:black;
}
.form_input{
    width: 90%;
    margin-left: 25px;  
}
.form_input:hover{
    border-color: #0b25bb;
    box-shadow: none;
}
.form-control:focus {
    border-color: #0004ff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 47, 255, 0.6);
  }
.top{
    margin-top: 10px;
}
.form_label{
    margin-left: 25px;
    margin-bottom: 1px;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    margin-top: 10px;
    color: #0b74bb;
}
.form_label2{
    margin-left: 0px;
    margin-left: 25px;
    margin-bottom: 1px;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    color: #0b74bb;
}

@media(max-width:800px){
    .form_label_sec{
        width: 90%;
        margin-left: 25px;
    }
    .form_label2{
        margin-left: 25px;
    }
}





