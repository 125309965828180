.menu_l{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    /* height: 40px; */
    line-height: 3rem;
    /* background-color: #f3f2f0f5; */
    border-bottom: 1px solid orangered;
}
#menu ul {
    padding:0px;
    margin:0px;
    background-color: rgba(196, 17, 17, 0.192);
    list-style:none;
}
#menu  li { 
    display: inline-block; 
    font-size: 19px;
    position: relative;
    padding: 2px 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-decoration: none;
}
#menu li a {
    
    display: inline-block;
  
    /* border-bottom:3px solid #EDEDED; */
    
}
/*Botão sair*/
.header_top_button{
    background-color: #f3f2f0f5;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.header_top_button:hover{
    background-color: orangered;
    color: white;
    font-family: italic;
}

@media(max-width:800px){
    .nav-item{
        width: 100%;
    }
    .Navbar{
        background-color: #f3f2f0f5;
    }
    .menu_l{
        height: 60px;
        line-height: 2rem; 
    }
    .header_top_button{
        width: 100%;
        text-align: left;
    }
}
.menu_img{
    width: 19px;
    margin-top: -3px; 
    margin-right: 4px;
}
.menu_img_home{
    margin-top: -3px;
}
.menu_exit{
    text-decoration: none;
    background-color: none;
}
.menu_color1{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color2{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color3{
    color: black;
    margin-right: 4px;
    margin-bottom: 1px;
}
.menu_color4{
    color: black;
    border-radius: 1px;
    padding: 0;
    /* box-shadow: 1px 1px 1px 1px rgb(125, 131, 141); */
}
.menu_color1:hover{
    color: #d8521d;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color2:hover{
    color: #ff0000;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color3:hover{
    color: #548b54;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
}
.menu_color4:hover{
    color: #b21cf7d7;
    box-shadow: 0.1px 0.1px 2px 2px #87838ad7;
    /* background-color: aliceblue; */
}


